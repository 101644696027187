import React from "react";
import { graphql, Link } from "gatsby";
import { List, Card } from "antd";
import Helmet from "react-helmet";
import config from "../utils/siteConfig";
import Layout from "../components/Layout/Layout";
import CanvasCollection from "../components/CanvasCollection";
import PageFooter from "../components/Layout/PageFooter/";
import SEO from "../components/SEO";

const PrintsTemplate = ({ data, location, pageContext }) => {
  const {
    title,
    printCollections: collections,
    subtitle,
  } = data.contentfulPage;
  const subtitleHtml = subtitle && subtitle.childMarkdownRemark.html;
  const { slug } = pageContext;
  const postNode = data.contentfulPage;

  return (
    <Layout location={location}>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} pageSEO />

      <div className="container">
        <div className="simple-title">
          <h1>{title}</h1>
          {subtitleHtml && (
            <div dangerouslySetInnerHTML={{ __html: subtitleHtml }} />
          )}
        </div>

        {collections.map((collection, i) => (
          <div key={`collection-${i}`}>
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 4,
                xl: 4,
                xxl: 4,
              }}
              dataSource={collection.elements}
              renderItem={(item) => (
                <Link className="grid-list-item" to={`/print/${item.id}`}>
                  <List.Item>
                    <Card bordered={false}>
                      <img
                        style={{
                          maxWidth: "100%",
                          // height: "300px",
                          // width: "auto",
                        }}
                        src={item.photo.fluid.src}
                      />
                      <div className="item">
                        <h3 className="title">{item.title}</h3>
                        {item.isSold && (
                          <h5
                            style={{ color: "red", margin: "0", padding: "0" }}
                          >
                            SOLD
                          </h5>
                        )}
                      </div>
                    </Card>
                  </List.Item>
                </Link>
              )}
            />
          </div>
        ))}
      </div>

      <PageFooter />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      subtitle {
        childMarkdownRemark {
          html
        }
      }
      metaDescription {
        internal {
          content
        }
      }
      body {
        json
      }
      printCollections {
        title
        elements {
          id
          title
          isSold
          photo {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;

export default PrintsTemplate;
